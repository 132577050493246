import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Navbar from '../components/Navbar';
import UserContext from "../context/UserContext";

export const ProtectedLayout = () => {
    const { user, setUser } = useContext(UserContext);

    if (!user) {
        return <Navigate to="/login" />;
    }

    return (
        // <div className="container gx-5 gy-5 px-4">
        //     <div className='row'>
        //         <Navigation />
        //     </div>
        //     <div className='row gy-4 mt-2 mb-3'>
        //         <div className='col-lg-3'>
        //             <Sidebar />
        //         </div>
        //         <div className='col-lg-9'>
        //             <Outlet />
        //         </div>
        //     </div>
        // </div>

        <div className="container-fuild overflow-x position-relative">
           <Navbar />
           <Outlet />
        </div> 
    )
};