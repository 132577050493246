import './App.css';
import React from 'react'
import HomePage from './pages/HomePage';
import { Routes,Route } from 'react-router-dom';
import DetailPage from './pages/DetailPage';
import UserContext from './context/UserContext';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import { useState,useMemo  } from 'react';
import VisibleLayout from './layouts/VisibleLayout';
import { ProtectedLayout } from './layouts/ProtectedLayout';
import PageNotFound from './pages/PageNotFound';


function App() {
    const [user,setUser] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))
                                                                   : null);
    const value = useMemo(
        () => ({ user, setUser }),
        [user]
    );
    return (
        <>        
        {/* <div className="container-fuild overflow-x">
           <Navbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/page/:page" element={<HomePage />} />
                <Route path="/detail/:hash" element={<DetailPage />} />
            </Routes>
        </div> */}
        <UserContext.Provider value={value}>
            <Routes>
                <Route element={<VisibleLayout />}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path='/register' element={<RegisterPage />} />
                </Route>

                <Route path="/" element={<ProtectedLayout />}>
                    <Route path="" element={<HomePage />} />
                    <Route path="page/:page" element={<HomePage />} />
                    <Route path="detail/:hash" element={<DetailPage />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </UserContext.Provider>

</>
    )
}

export default App;
