import React, { useContext, useState } from 'react'
import UserContext from '../context/UserContext';
import axios from 'axios';
import '../css/LoginPage.css';

function LoginPage() {
  const { user, setUser } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  //api_v1
  //eqEuLVNXgTQcP5ST

  const submithandler = async (e) => {
    e.preventDefault()
    await axios.post('/api/taksit/rest/register', { username, password }, {
      headers: {
        'Content-Type': 'application/json',
        entreprise: 'Diar Dzair',
        projet: 'DiarDzair Alsalam',
      }
    }).then(res => {
      if(res.data.errorCode){
        setError(res.data.errorMessage);
      }else if(res.data.roles.includes('ROLE_CREDIT_ALSALAM_ADMIN') || res.data.roles.includes('ROLE_CREDIT_ALSALAM_OPR')){ 
        setUser(res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data));
      }else{
        setError("vous n'êtes pas autorisé à vous connecter");
      }
    }).catch(err => {
      console.log(err.message);
      setError('Invalid Username/Password')
    })
  }

  return (
    <div className='login'>
      <h1>Login</h1>
      <form onSubmit={submithandler}>
        <div className="box">
          <input type="text" required
            onChange={e => setUsername(e.target.value)} value={username} />
          <span>Username</span>
        </div>
        <div className="box">
          <input type="password" required
            onChange={e => setPassword(e.target.value)} value={password} />
          <span>Password</span>
        </div>
        {
          error ? (          
            <div className='box'>
              <span className='error-message'>{error}</span>
            </div>
          ) :  ""
        }
        <div className='box text-center'>
          <button type='submit'>
            Login
            <svg width="34px" heigh="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.06 40.25">
              <defs><style>{`.cls-modal-0{fill:#fff;}`}</style></defs>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <g id="Layer_2-2" data-name="Layer 2">
                    <g id="Layer_1-2-2" data-name="Layer 1-2">
                      <g id="Layer_2-2-2" data-name="Layer 2-2">
                        <g id="Layer_1-2-2-2" data-name="Layer 1-2-2"><path class="cls-modal-0" d="M61,5.42a5.47,5.47,0,0,1-2.25,4.44L16.55,40.21a.13.13,0,0,1-.18,0h0l-.21-.32h0L1,18.11a5.49,5.49,0,0,1,1.28-7.57,5.27,5.27,0,0,1,7.36,1.19l.07.11L19,25.2,52.62,1A5.27,5.27,0,0,1,60,2.2a.86.86,0,0,1,.07.1,5.37,5.37,0,0,1,1,3.12Z" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </form>
    </div>
  )
}

export default LoginPage