import React, { useContext } from 'react'
import { Link, Navigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import UserContext from '../context/UserContext';
import '../css/PageNotFound.css'

const PageNotFound = () => {
    const { user, setUser } = useContext(UserContext);

    if (!user) {
        return <Navigate to="/login" />;
    }
    return (
        <div className="container-fuild overflow-x position-relative">
            <Navbar />
            <div className='page-not-found-section'>
                <h1>Oops</h1>
                <h2>Error 404 Page Not Found</h2>
                <Link to={`/`}>
                    Retourner à l'accueil
                </Link>
            </div>
        </div>
    )
}

export default PageNotFound