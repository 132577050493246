import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import '../css/HomePage.css'
import LoadingComponent from '../components/LoadingComponent';
import UserContext from '../context/UserContext';

function ListCommands() {

    const navigate = useNavigate();
    const { page = 1 } = useParams();

    const [id, setId] = useState('');
    const [nom, setNom] = useState('');
    const [dossierBanque, setDossierBanque] = useState('');
    const [dossierClient, setDossierClient] = useState('');
    const [etat, setEtat] = useState('');
    const [actionBanque, setActionBanque] = useState('');
    const [pages, setPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [dateInsertion, setDateInsertion] = useState("");
    const [demandes, setDemandes] = useState(null);
    const [tableShow, setTableShow] = useState(false);
    const [hash, setHash] = useState("");
    const [nbrAll, setNbrAll] = useState('');
    const [nbrConsult, setNbrConsult] = useState('');
    const [nbrNotify, setNbrNotify] = useState('');
    const [nbrConfirmDelivery, setNbrConfirmDelivery] = useState('');
    const [nbrConfirmPayment, setNbrConfirmPayment] = useState('');
    const [nbrReception, setNbrReception] = useState('');
    const [nbrControl, setNbrControl] = useState('');
    const [nbrFollow, setNbrFollow] = useState('');
    const [nbrComplement, setNbrComplement] = useState('');
    const [nbrReject, setNbrReject] = useState('');
    const [filter, setFilter] = useState(false);

    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        getCommands();
    }, [page, actionBanque])

    useEffect(() => {
        if (parseInt(page) <= parseInt(pages) - 3) {
            setPageNumber(parseInt(page));
        } else {
            setPageNumber(parseInt(pages) - 3)
        }
    }, [page, pages])

    useEffect(() => {
        setNom('');
        setDossierBanque('');
        setDossierClient('');
        setEtat('');
    }, [id])

    useEffect(() => {
        setId('');
    }, [nom, dossierBanque, dossierClient, etat])

    useEffect(() => {
        getCommands();
    }, [filter])


    /****** Begin Methods ******/
    function getDate(dd) {
        var s = new Date(dd.timestamp * 1000).toLocaleDateString("fr-FR")
        return s;
    }
    
    const getStatus = (number) => {
        if (number === 0) {
            return "Non_Traité";
        }
        else if (number === 1) {
            return "Accepter";
        }
        else if (number === 2) {
            return "Refuser";
        }
        else if (number === 3) {
            return "Approuver";
        }
        else if (number === 4) {
            return "Annuler";
        }
        else if (number === 5) {
            return 'Financable'
        }
        else if (number === 6) {
            return 'Non Financable'
        }
        else if (number === 7) {
            return 'Rejeter'
        }
        else if (number === 8) {
            return 'Controler'
        }
        else{
            return ''
        }
    }
    function checkSize(value) {
        if (value.length > 20)
            return value.substring(0, 20);
        else
            return value;
    }
    function onSearch() {
        setActionBanque('');
        navigate(`/page/${1}`);
        document.querySelector(".home-page .taches-section ul li.tache-active").classList.remove('tache-active');
        document.querySelector(".home-page .taches-section ul li:first-child").classList.add('tache-active');
        getCommands();
    }
    function tache_active(ab, event) {
        setId('');
        setNom('');
        setDossierBanque('');
        setDossierClient('');
        setEtat('');
        if (ab === '') {
            setFilter(fl => !fl);
        }
        document.querySelector(".home-page .taches-section ul li.tache-active").classList.remove('tache-active');
        event.currentTarget.classList.add("tache-active");
        setActionBanque(ab);
        navigate(`/page/${1}`);
    }
    function onclickNext() {
        if (page < pages) {
            navigate(`/page/${parseInt(page) + 1}`)
        }
    }
    function onclickPrevious() {
        if (page > 1) {
            navigate(`/page/${parseInt(page) - 1}`)
        }
    }

    const getCommands = async () => {
        var cp = page || 1;
        setTableShow(false);
        var orderId = "";
        var current = new Date();
        var day = current.getDate();
        var month = current.getMonth() + 1;
        var year = current.getFullYear();
        var df = year.toString().substring(2) + '' + month + '' + day;

        var model = {};
        if (actionBanque !== '') {
            model = { username: user.user, orderId: user.orderId, dd: '191015', df: df, actionBanque: actionBanque }
        } else {
            model = {
                id, date_insertion: "", nom, dossierBanque: dossierBanque, dossier2: dossierClient, etat, username: user.user,
                orderId: user.orderId, dd: '191015', df: df, actionBanque: ''
            }
        }

        await axios
            .post(`/api/taksit/rest/get/actionBanque`,
                { username: user.user, orderId: user.orderId, dd: '191015', df: df },
                {
                    headers: {
                        Diar_Dzair: "List of Taches",
                    },
                })
            .then((res) => {
                // this.demandes = res.data["0"];
                // this.pages = res.data.pageTotal;
                // console.log('action banque', res.data);
                setNbrAll(res.data['p0']);
                setNbrConsult(res.data['p1']);
                setNbrNotify(res.data['p2']);
                setNbrConfirmDelivery(res.data['p3']);
                setNbrConfirmPayment(res.data['p4']);
                setNbrReception(res.data['p5']);
                setNbrControl(res.data['p6']);
                setNbrFollow(res.data['p7']);
                setNbrComplement(res.data['p8']);
                setNbrReject(res.data['p9']);
            })
            .catch((err) => {
                console.log(err);
            });
        await axios
            .post(`/api/taksit/rest/get/formData?pageSize=${pageSize}&page=${cp}`, model,
                {
                    headers: {
                        Diar_Dzair: "List of Demands",
                    },
                })
            .then((res) => {
                setDemandes(res.data["0"]);
                setPages(res.data.pageTotal);
                //   setCurrentPage(cp);
                setTableShow(true);
                // console.log(this.demandes)
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const onCheckDossierBanque = async () => {
        var orderId = "";
        await axios.get("/api/taksit/rest/register?username=api_v1&password=eqEuLVNXgTQcP5ST")
            .then(async (res) => {
                orderId = res.data.orderId;
                await axios.post(`/api/taksit/rest/set/activeDossierBanque`,
                    { username: 'api_v1', orderId: orderId, hash: this.hash },
                    { headers: { Diar_Dzair: "Check Dossier Banque", } })
                    .then(res => {
                        console.log('Successs');
                        console.log(res)
                        getCommands();
                    }).catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }


    // function onGetDetail(hash) {
    //     var anchor = document.createElement('a');
    //     anchor.href = `https://www.diardzair.com.dz/taksit/bank/${hash}`;
    //     anchor.target = "_blank";
    //     anchor.click();
    // }




    return (

        <div className="home-page">
            <div className="row">
                <div className="filter-section">
                    <h2>Filtrage</h2>
                    <div className="search-by-id-section">
                        <div className="row">
                            <div className="col-lg-6 col-md-9 col-sm-12">
                                <div className="form">
                                    <div className="row">
                                        <div className="form-group col-md-4 col-12">
                                            <span className="sub-title">Recherche par ID</span>
                                        </div>
                                        <div className="form-group col-md-4 col-12">
                                            <label>Identifiant</label>
                                            <input className="form-control" placeholder="Identifiant" type="number"
                                                name='id' value={id} onChange={e => setId(e.target.value)} />
                                        </div>
                                        <div className="form-group col-md-4 col-12 position-relative">
                                            <span className="btn absolute-bottom-right"
                                                onClick={() => onSearch()}>Recherche</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-advanced-section">
                        <div className="row">
                            <div className="col-md-2 col-sm-12">
                                <span className="sub-title">Recherche Avancée</span>
                            </div>
                            <div className="col-md-10 col-sm-12">
                                <div className="row">
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Nom</label>
                                        <input type="text" className="form-control" placeholder="Nom" id='nom' value={nom}
                                            onChange={e => setNom(e.target.value)} />
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Dossier Banque</label>
                                        <select className="form-select" value={dossierBanque} onChange={e => setDossierBanque(e.target.value)}>
                                            <option value="">Tout</option>
                                            <option value="1">True</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Dossier Garantie</label>
                                        <select className="form-select" value={dossierClient} onChange={e => setDossierClient(e.target.value)}>
                                            <option value="">Tout</option>
                                            <option value="1">True</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                        <label>Etat</label>
                                        <select className="form-select" value={etat} onChange={e => setEtat(e.target.value)} >
                                            <option value="">Tout</option>                                           
                                            <option value="0">Non Traité</option>
                                            <option value="1">Accepter</option>
                                            <option value="2">Refuser</option>
                                            <option value="3">Approuver</option>
                                            <option value="4">Annuler</option>
                                            <option value="5">Finançable</option>
                                            <option value="6">Non Finançable</option>
                                            <option value="7">Rejeter</option>
                                            <option value="8">Contrôler</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12 position-relative">
                                        <span className="btn absolute-bottom-right" onClick={() => onSearch()}>Recherche</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-12 padding-right margin-bottom">
                    <div className="taches-section">
                        <h2>Tâches</h2>
                        <ul>
                            <li className="tache-active" onClick={e => tache_active('', e)}>
                                <div className="no-color">
                                    <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
                                        <defs>
                                            <style>{`.cls-detail-0{fill:none;stroke:#535353;stroke-miterlimit:10;stroke-width:3px;}`}</style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Layer_2-2" data-name="Layer 2">
                                                    <g id="Layer_1-2-2" data-name="Layer 1-2">
                                                        <path className="cls-detail-0" d="M6.69,1.5H9.31A5.24,5.24,0,0,1,14.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H6.69A5.24,5.24,0,0,1,1.5,9.46V6.79A5.24,5.24,0,0,1,6.69,1.5Z" />
                                                        <path className="cls-detail-0" d="M23.69,1.5h2.62A5.24,5.24,0,0,1,31.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H23.69A5.24,5.24,0,0,1,18.5,9.46V6.79A5.24,5.24,0,0,1,23.69,1.5Z" />
                                                        <path className="cls-detail-0" d="M6.69,18.25H9.31a5.24,5.24,0,0,1,5.19,5.29v2.67A5.24,5.24,0,0,1,9.31,31.5H6.69A5.24,5.24,0,0,1,1.5,26.21V23.54A5.24,5.24,0,0,1,6.69,18.25Z" />
                                                        <path className="cls-detail-0" d="M23.69,18.25h2.62a5.24,5.24,0,0,1,5.19,5.29v2.67a5.24,5.24,0,0,1-5.19,5.29H23.69a5.24,5.24,0,0,1-5.19-5.29V23.54A5.24,5.24,0,0,1,23.69,18.25Z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Tout ({nbrAll})</span>
                                </div>
                                <div className="with-color">
                                    <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
                                        <defs>
                                            <style>{`.cls-detail-0b{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px;}`}</style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Layer_2-2" data-name="Layer 2">
                                                    <g id="Layer_1-2-2" data-name="Layer 1-2">
                                                        <path className="cls-detail-0b" d="M6.69,1.5H9.31A5.24,5.24,0,0,1,14.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H6.69A5.24,5.24,0,0,1,1.5,9.46V6.79A5.24,5.24,0,0,1,6.69,1.5Z" />
                                                        <path className="cls-detail-0b" d="M23.69,1.5h2.62A5.24,5.24,0,0,1,31.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H23.69A5.24,5.24,0,0,1,18.5,9.46V6.79A5.24,5.24,0,0,1,23.69,1.5Z" />
                                                        <path className="cls-detail-0b" d="M6.69,18.25H9.31a5.24,5.24,0,0,1,5.19,5.29v2.67A5.24,5.24,0,0,1,9.31,31.5H6.69A5.24,5.24,0,0,1,1.5,26.21V23.54A5.24,5.24,0,0,1,6.69,18.25Z" />
                                                        <path className="cls-detail-0b" d="M23.69,18.25h2.62a5.24,5.24,0,0,1,5.19,5.29v2.67a5.24,5.24,0,0,1-5.19,5.29H23.69a5.24,5.24,0,0,1-5.19-5.29V23.54A5.24,5.24,0,0,1,23.69,18.25Z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Tout ({nbrAll})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('1', e)}>
                                <div className="no-color">
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-1 {
                                                            fill: #535353;
                                                            }
                                                    `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path className="cls-1"
                                                    d="M38.77,9.44a2.54,2.54,0,0,0-3.51,0l-.46.46a.88.88,0,0,1-.63.26h-.11a.86.86,0,0,1-.63-.26l-.51-.5a.9.9,0,0,1-.26-.64.88.88,0,0,1,.34-.7l.41-.33a1.83,1.83,0,0,0,.7-1.45A1.91,1.91,0,0,0,33.57,5L32.24,3.63a2.54,2.54,0,0,0-3.51,0l-1,1a3,3,0,0,0-.9,2.17A3.07,3.07,0,0,0,27.1,8a13.58,13.58,0,0,0,7,7.07l1.47.66a3,3,0,0,0,3.38-.63l1-1a2.46,2.46,0,0,0,0-3.5Zm.12,3.62-1,1a1.61,1.61,0,0,1-1.77.33l-1.47-.65a12.13,12.13,0,0,1-6.23-6.32,1.53,1.53,0,0,1-.13-.64,1.58,1.58,0,0,1,.46-1.13l1-1a1,1,0,0,1,1.45,0L32.54,6a.38.38,0,0,1,.12.29.41.41,0,0,1-.15.32l-.42.33a2.34,2.34,0,0,0-.88,1.83,2.3,2.3,0,0,0,.69,1.66l.5.5a2.3,2.3,0,0,0,1.66.69h.11a2.36,2.36,0,0,0,1.66-.68l.46-.47a1,1,0,0,1,1.45,0l1.15,1.15a1,1,0,0,1,.3.73A1,1,0,0,1,38.89,13.06Z" />
                                                <path className="cls-1"
                                                    d="M13.4,36.61l1.72-1,.5.21.52,1.9h4l.52-1.9.5-.21,1.72,1,2.83-2.84-1-1.71a5.32,5.32,0,0,0,.21-.5l1.9-.52V27L25,26.51a4.4,4.4,0,0,0-.21-.5l1-1.72-2.83-2.83-1.72,1a5.32,5.32,0,0,0-.5-.21l-.52-1.91h-4l-.52,1.91a5.32,5.32,0,0,0-.5.21l-1.72-1-2.83,2.83,1,1.72a4.4,4.4,0,0,0-.21.5L9.44,27v4l1.9.52c.06.16.13.33.21.5l-1,1.71Zm-2.51-6.68v-1.8l1.59-.43.12-.38a5.69,5.69,0,0,1,.41-1L13.2,26l-.82-1.44,1.27-1.26,1.43.82.36-.19a6.53,6.53,0,0,1,1-.42l.38-.11.44-1.6H19l.44,1.6.38.11a6.53,6.53,0,0,1,1,.42l.36.18,1.43-.82,1.27,1.27L23.09,26l.19.35a5.69,5.69,0,0,1,.41,1l.12.38,1.59.44v1.79l-1.59.44-.12.38a5.6,5.6,0,0,1-.41,1l-.19.35.82,1.44L22.64,34.8,21.21,34l-.36.19a5.31,5.31,0,0,1-1,.41l-.38.12L19,36.29H17.25l-.44-1.59-.38-.12a5.6,5.6,0,0,1-1-.41L15.08,34l-1.43.82-1.27-1.27.82-1.44L13,31.74a5.6,5.6,0,0,1-.41-1l-.12-.38Z" />
                                                <path className="cls-1"
                                                    d="M18.15,33.39A4.36,4.36,0,1,0,13.79,29,4.36,4.36,0,0,0,18.15,33.39Zm0-7.26A2.91,2.91,0,1,1,15.24,29,2.9,2.9,0,0,1,18.15,26.13Z" />
                                                <path className="cls-1"
                                                    d="M42.82,0H24a2.19,2.19,0,0,0-2.18,2.18V8h-8a2.19,2.19,0,0,0-2.1,1.63A13.71,13.71,0,0,0,4.35,21.77v3.69a5.09,5.09,0,0,0-4.35,5v2.91a5.09,5.09,0,0,0,5.08,5.08H8.71V25.4H5.81V21.77a12.24,12.24,0,0,1,6.06-10.6,2.17,2.17,0,0,0,1.92,1.17h8v4.35A2.19,2.19,0,0,0,24,18.87h6.18a12.31,12.31,0,0,1,.35,2.9V25.4h-2.9V38.47H29a3.64,3.64,0,0,1-3.56,2.9H21.67a2.9,2.9,0,0,0-2.8-2.18H16A2.91,2.91,0,1,0,16,45h2.9a2.9,2.9,0,0,0,2.8-2.18H25.4a5.09,5.09,0,0,0,5-4.35h.78a5.09,5.09,0,0,0,5.08-5.08V30.48a5.09,5.09,0,0,0-4.35-5V21.77a14.07,14.07,0,0,0-.31-2.9h1.76v4.21l6.74-4.21h2.69A2.19,2.19,0,0,0,45,16.69V2.18A2.19,2.19,0,0,0,42.82,0ZM7.26,37H5.81V28.31H4.35v8.63a3.63,3.63,0,0,1-2.9-3.55V30.48a3.64,3.64,0,0,1,3.63-3.63H7.26Zm11.61,6.53H16a1.45,1.45,0,1,1,0-2.9h2.9a1.45,1.45,0,0,1,0,2.9Zm16-13.07v2.91a3.63,3.63,0,0,1-2.9,3.55V28.31H30.48V37H29V26.85h2.18A3.64,3.64,0,0,1,34.84,30.48Zm-17.42-21v1.45H16V9.44Zm-4.36.72a.73.73,0,0,1,.73-.72h.73v1.45h-.73A.73.73,0,0,1,13.06,10.16Zm5.81.73V9.44h2.9v1.45Zm24.68,5.8a.73.73,0,0,1-.73.73H39.71l-4.87,3v-3H24a.73.73,0,0,1-.72-.73V2.18A.73.73,0,0,1,24,1.45H42.82a.73.73,0,0,1,.73.73Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Consultation Central risque ({nbrConsult})</span>
                                </div>
                                <div className="with-color">
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-b {
                                                            fill: #fff;
                                                            }
                                                    `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path className="cls-b"
                                                    d="M38.77,9.44a2.54,2.54,0,0,0-3.51,0l-.46.46a.88.88,0,0,1-.63.26h-.11a.86.86,0,0,1-.63-.26l-.51-.5a.9.9,0,0,1-.26-.64.88.88,0,0,1,.34-.7l.41-.33a1.83,1.83,0,0,0,.7-1.45A1.91,1.91,0,0,0,33.57,5L32.24,3.63a2.54,2.54,0,0,0-3.51,0l-1,1a3,3,0,0,0-.9,2.17A3.07,3.07,0,0,0,27.1,8a13.58,13.58,0,0,0,7,7.07l1.47.66a3,3,0,0,0,3.38-.63l1-1a2.46,2.46,0,0,0,0-3.5Zm.12,3.62-1,1a1.61,1.61,0,0,1-1.77.33l-1.47-.65a12.13,12.13,0,0,1-6.23-6.32,1.53,1.53,0,0,1-.13-.64,1.58,1.58,0,0,1,.46-1.13l1-1a1,1,0,0,1,1.45,0L32.54,6a.38.38,0,0,1,.12.29.41.41,0,0,1-.15.32l-.42.33a2.34,2.34,0,0,0-.88,1.83,2.3,2.3,0,0,0,.69,1.66l.5.5a2.3,2.3,0,0,0,1.66.69h.11a2.36,2.36,0,0,0,1.66-.68l.46-.47a1,1,0,0,1,1.45,0l1.15,1.15a1,1,0,0,1,.3.73A1,1,0,0,1,38.89,13.06Z" />
                                                <path className="cls-b"
                                                    d="M13.4,36.61l1.72-1,.5.21.52,1.9h4l.52-1.9.5-.21,1.72,1,2.83-2.84-1-1.71a5.32,5.32,0,0,0,.21-.5l1.9-.52V27L25,26.51a4.4,4.4,0,0,0-.21-.5l1-1.72-2.83-2.83-1.72,1a5.32,5.32,0,0,0-.5-.21l-.52-1.91h-4l-.52,1.91a5.32,5.32,0,0,0-.5.21l-1.72-1-2.83,2.83,1,1.72a4.4,4.4,0,0,0-.21.5L9.44,27v4l1.9.52c.06.16.13.33.21.5l-1,1.71Zm-2.51-6.68v-1.8l1.59-.43.12-.38a5.69,5.69,0,0,1,.41-1L13.2,26l-.82-1.44,1.27-1.26,1.43.82.36-.19a6.53,6.53,0,0,1,1-.42l.38-.11.44-1.6H19l.44,1.6.38.11a6.53,6.53,0,0,1,1,.42l.36.18,1.43-.82,1.27,1.27L23.09,26l.19.35a5.69,5.69,0,0,1,.41,1l.12.38,1.59.44v1.79l-1.59.44-.12.38a5.6,5.6,0,0,1-.41,1l-.19.35.82,1.44L22.64,34.8,21.21,34l-.36.19a5.31,5.31,0,0,1-1,.41l-.38.12L19,36.29H17.25l-.44-1.59-.38-.12a5.6,5.6,0,0,1-1-.41L15.08,34l-1.43.82-1.27-1.27.82-1.44L13,31.74a5.6,5.6,0,0,1-.41-1l-.12-.38Z" />
                                                <path className="cls-b"
                                                    d="M18.15,33.39A4.36,4.36,0,1,0,13.79,29,4.36,4.36,0,0,0,18.15,33.39Zm0-7.26A2.91,2.91,0,1,1,15.24,29,2.9,2.9,0,0,1,18.15,26.13Z" />
                                                <path className="cls-b"
                                                    d="M42.82,0H24a2.19,2.19,0,0,0-2.18,2.18V8h-8a2.19,2.19,0,0,0-2.1,1.63A13.71,13.71,0,0,0,4.35,21.77v3.69a5.09,5.09,0,0,0-4.35,5v2.91a5.09,5.09,0,0,0,5.08,5.08H8.71V25.4H5.81V21.77a12.24,12.24,0,0,1,6.06-10.6,2.17,2.17,0,0,0,1.92,1.17h8v4.35A2.19,2.19,0,0,0,24,18.87h6.18a12.31,12.31,0,0,1,.35,2.9V25.4h-2.9V38.47H29a3.64,3.64,0,0,1-3.56,2.9H21.67a2.9,2.9,0,0,0-2.8-2.18H16A2.91,2.91,0,1,0,16,45h2.9a2.9,2.9,0,0,0,2.8-2.18H25.4a5.09,5.09,0,0,0,5-4.35h.78a5.09,5.09,0,0,0,5.08-5.08V30.48a5.09,5.09,0,0,0-4.35-5V21.77a14.07,14.07,0,0,0-.31-2.9h1.76v4.21l6.74-4.21h2.69A2.19,2.19,0,0,0,45,16.69V2.18A2.19,2.19,0,0,0,42.82,0ZM7.26,37H5.81V28.31H4.35v8.63a3.63,3.63,0,0,1-2.9-3.55V30.48a3.64,3.64,0,0,1,3.63-3.63H7.26Zm11.61,6.53H16a1.45,1.45,0,1,1,0-2.9h2.9a1.45,1.45,0,0,1,0,2.9Zm16-13.07v2.91a3.63,3.63,0,0,1-2.9,3.55V28.31H30.48V37H29V26.85h2.18A3.64,3.64,0,0,1,34.84,30.48Zm-17.42-21v1.45H16V9.44Zm-4.36.72a.73.73,0,0,1,.73-.72h.73v1.45h-.73A.73.73,0,0,1,13.06,10.16Zm5.81.73V9.44h2.9v1.45Zm24.68,5.8a.73.73,0,0,1-.73.73H39.71l-4.87,3v-3H24a.73.73,0,0,1-.72-.73V2.18A.73.73,0,0,1,24,1.45H42.82a.73.73,0,0,1,.73.73Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Consultation Central risque ({nbrConsult})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('2', e)}>
                                <div className="no-color">
                                    <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-1 {
                                                            fill: #535353;
                                                            }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Layer_2-2" data-name="Layer 2">
                                                    <path className="cls-1"
                                                        d="M19,38a6.1,6.1,0,0,1-6.33-5.85,1.53,1.53,0,0,1,1.58-1.46h9.5a1.53,1.53,0,0,1,1.58,1.46A6.1,6.1,0,0,1,19,38Zm-2.74-4.38a3.33,3.33,0,0,0,4.33,1.06,3.09,3.09,0,0,0,1.15-1.06Z" />
                                                    <path className="cls-1"
                                                        d="M22.17,5.85H15.83a1.53,1.53,0,0,1-1.58-1.47A4.58,4.58,0,0,1,19,0a4.58,4.58,0,0,1,4.75,4.38A1.53,1.53,0,0,1,22.17,5.85Z" />
                                                    <path className="cls-1"
                                                        d="M36.42,33.62H1.58A1.53,1.53,0,0,1,0,32.15a10,10,0,0,1,4.75-8.4V16.08C4.75,8.85,11.07,3,18.9,2.92h.18c7.87,0,14.25,5.89,14.25,13.16v7.67A10,10,0,0,1,38,32.15h0A1.53,1.53,0,0,1,36.42,33.62ZM3.32,30.69H34.67a7.31,7.31,0,0,0-3.8-4.86,1.47,1.47,0,0,1-.79-1.28V16.08c0-5.65-5-10.23-11.08-10.23S7.92,10.43,7.92,16.08v8.47a1.46,1.46,0,0,1-.8,1.28A7.37,7.37,0,0,0,3.32,30.69Z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Notification Banque ({nbrNotify})</span>
                                </div>
                                <div className="with-color">
                                    <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-b {
                                                            fill: #fff;
                                                            }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="Layer_2-2" data-name="Layer 2">
                                                    <path className="cls-b"
                                                        d="M19,38a6.1,6.1,0,0,1-6.33-5.85,1.53,1.53,0,0,1,1.58-1.46h9.5a1.53,1.53,0,0,1,1.58,1.46A6.1,6.1,0,0,1,19,38Zm-2.74-4.38a3.33,3.33,0,0,0,4.33,1.06,3.09,3.09,0,0,0,1.15-1.06Z" />
                                                    <path className="cls-b"
                                                        d="M22.17,5.85H15.83a1.53,1.53,0,0,1-1.58-1.47A4.58,4.58,0,0,1,19,0a4.58,4.58,0,0,1,4.75,4.38A1.53,1.53,0,0,1,22.17,5.85Z" />
                                                    <path className="cls-b"
                                                        d="M36.42,33.62H1.58A1.53,1.53,0,0,1,0,32.15a10,10,0,0,1,4.75-8.4V16.08C4.75,8.85,11.07,3,18.9,2.92h.18c7.87,0,14.25,5.89,14.25,13.16v7.67A10,10,0,0,1,38,32.15h0A1.53,1.53,0,0,1,36.42,33.62ZM3.32,30.69H34.67a7.31,7.31,0,0,0-3.8-4.86,1.47,1.47,0,0,1-.79-1.28V16.08c0-5.65-5-10.23-11.08-10.23S7.92,10.43,7.92,16.08v8.47a1.46,1.46,0,0,1-.8,1.28A7.37,7.37,0,0,0,3.32,30.69Z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Notification Banque ({nbrNotify})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('3', e)}>
                                <div className="no-color">
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-1 {
                                                            fill: #535353;
                                                            }
                                                    `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path className="cls-1"
                                                    d="M6.15,16.67H31.76L29.27,28.49h3.88L35.68,16.7H38v-4H31.47L24.42,0,21.17,1.92l6,10.82H10.85l6-10.82L13.53,0l-7,12.74H0v3.93H2.28L5.73,32.88a1.91,1.91,0,0,0,1.85,1.53h17.1v-4H9.1Z" />
                                                <polygon className="cls-1"
                                                    points="42.31 31 34.16 39.44 29.81 34.94 27.12 37.71 34.16 45 45 33.79 42.31 31" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Confirmation Livraison Banque ({nbrConfirmDelivery})</span>
                                </div>

                                <div className="with-color">
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-b {
                                                            fill: #fff;
                                                            }
                                                    `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <path className="cls-b"
                                                    d="M6.15,16.67H31.76L29.27,28.49h3.88L35.68,16.7H38v-4H31.47L24.42,0,21.17,1.92l6,10.82H10.85l6-10.82L13.53,0l-7,12.74H0v3.93H2.28L5.73,32.88a1.91,1.91,0,0,0,1.85,1.53h17.1v-4H9.1Z" />
                                                <polygon className="cls-b"
                                                    points="42.31 31 34.16 39.44 29.81 34.94 27.12 37.71 34.16 45 45 33.79 42.31 31" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Confirmation Livraison Banque ({nbrConfirmDelivery})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('4', e)}>
                                <div className="no-color">
                                    <svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-1 {
                                                            fill: #535353;
                                                            }
                                                    `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="_21._Verified" data-name=" 21. Verified">
                                                    <path className="cls-1"
                                                        d="M36.42,21.77a4.59,4.59,0,0,1,0-5.58l1.26-1.67a1.53,1.53,0,0,0-.36-2.21,2,2,0,0,0-.47-.23l-2-.63a4.77,4.77,0,0,1-3.44-4.51V4.87a1.62,1.62,0,0,0-1.65-1.58,1.7,1.7,0,0,0-.51.08L27.15,4a5.1,5.1,0,0,1-5.54-1.73L20.34.61A1.78,1.78,0,0,0,17.92.36a3.07,3.07,0,0,0-.26.25L16.39,2.28A5.09,5.09,0,0,1,10.85,4L8.78,3.37a1.67,1.67,0,0,0-2.08,1,1.34,1.34,0,0,0-.08.48V6.94a4.77,4.77,0,0,1-3.44,4.51l-2,.63a1.58,1.58,0,0,0-1.07,2,1.45,1.45,0,0,0,.24.44l1.26,1.67a4.59,4.59,0,0,1,0,5.58L.32,23.44a1.53,1.53,0,0,0,.36,2.21,2,2,0,0,0,.47.23l2,.63A4.77,4.77,0,0,1,6.62,31v2.07a1.62,1.62,0,0,0,1.65,1.58,1.82,1.82,0,0,0,.52-.08L10.84,34a5.08,5.08,0,0,1,5.56,1.73l1.26,1.66A1.71,1.71,0,0,0,20,37.7a1.55,1.55,0,0,0,.37-.35l1.26-1.66A5.1,5.1,0,0,1,27.16,34l2.05.63a1.67,1.67,0,0,0,2.09-1,1.31,1.31,0,0,0,.08-.48V31a4.78,4.78,0,0,1,3.44-4.52l2-.63a1.54,1.54,0,0,0,.83-2.44Zm-2.75,1.77a7.94,7.94,0,0,0-5.6,7.36A8.51,8.51,0,0,0,19,33.72a8.38,8.38,0,0,0-6.62-3.17,8.85,8.85,0,0,0-2.45.35,8,8,0,0,0-5.6-7.37,7.61,7.61,0,0,0,0-9.11,8,8,0,0,0,5.6-7.37A8.49,8.49,0,0,0,19,4.24a8.51,8.51,0,0,0,9.07,2.82,8,8,0,0,0,5.6,7.37A7.55,7.55,0,0,0,33.67,23.54Z" />
                                                    <path className="cls-1"
                                                        d="M24.46,14.69l-7.12,6.8-3.8-3.63a1.7,1.7,0,0,0-2.34,0,1.53,1.53,0,0,0,0,2.24l0,0,5,4.75a1.7,1.7,0,0,0,2.34,0l8.29-7.92a1.53,1.53,0,0,0,0-2.24A1.72,1.72,0,0,0,24.46,14.69Z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Confirmation Paiement Banque ({nbrConfirmPayment})</span>
                                </div>

                                <div className="with-color">
                                    <svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                                        <defs>
                                            <style>
                                                {`
                                                        .cls-b {
                                                            fill: #fff;
                                                            }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="_21._Verified" data-name=" 21. Verified">
                                                    <path className="cls-b"
                                                        d="M36.42,21.77a4.59,4.59,0,0,1,0-5.58l1.26-1.67a1.53,1.53,0,0,0-.36-2.21,2,2,0,0,0-.47-.23l-2-.63a4.77,4.77,0,0,1-3.44-4.51V4.87a1.62,1.62,0,0,0-1.65-1.58,1.7,1.7,0,0,0-.51.08L27.15,4a5.1,5.1,0,0,1-5.54-1.73L20.34.61A1.78,1.78,0,0,0,17.92.36a3.07,3.07,0,0,0-.26.25L16.39,2.28A5.09,5.09,0,0,1,10.85,4L8.78,3.37a1.67,1.67,0,0,0-2.08,1,1.34,1.34,0,0,0-.08.48V6.94a4.77,4.77,0,0,1-3.44,4.51l-2,.63a1.58,1.58,0,0,0-1.07,2,1.45,1.45,0,0,0,.24.44l1.26,1.67a4.59,4.59,0,0,1,0,5.58L.32,23.44a1.53,1.53,0,0,0,.36,2.21,2,2,0,0,0,.47.23l2,.63A4.77,4.77,0,0,1,6.62,31v2.07a1.62,1.62,0,0,0,1.65,1.58,1.82,1.82,0,0,0,.52-.08L10.84,34a5.08,5.08,0,0,1,5.56,1.73l1.26,1.66A1.71,1.71,0,0,0,20,37.7a1.55,1.55,0,0,0,.37-.35l1.26-1.66A5.1,5.1,0,0,1,27.16,34l2.05.63a1.67,1.67,0,0,0,2.09-1,1.31,1.31,0,0,0,.08-.48V31a4.78,4.78,0,0,1,3.44-4.52l2-.63a1.54,1.54,0,0,0,.83-2.44Zm-2.75,1.77a7.94,7.94,0,0,0-5.6,7.36A8.51,8.51,0,0,0,19,33.72a8.38,8.38,0,0,0-6.62-3.17,8.85,8.85,0,0,0-2.45.35,8,8,0,0,0-5.6-7.37,7.61,7.61,0,0,0,0-9.11,8,8,0,0,0,5.6-7.37A8.49,8.49,0,0,0,19,4.24a8.51,8.51,0,0,0,9.07,2.82,8,8,0,0,0,5.6,7.37A7.55,7.55,0,0,0,33.67,23.54Z" />
                                                    <path className="cls-b"
                                                        d="M24.46,14.69l-7.12,6.8-3.8-3.63a1.7,1.7,0,0,0-2.34,0,1.53,1.53,0,0,0,0,2.24l0,0,5,4.75a1.7,1.7,0,0,0,2.34,0l8.29-7.92a1.53,1.53,0,0,0,0-2.24A1.72,1.72,0,0,0,24.46,14.69Z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Confirmation Paiement Banque ({nbrConfirmPayment})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('5', e)}>
                                <div className="no-color">
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-11,
                                                    .cls-12 {
                                                    fill: none;
                                                    stroke: #535353;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    }
        
                                                    .cls-11 {
                                                    stroke-width: 2px;
                                                    }
        
                                                    .cls-12 {
                                                    stroke-width: 2px;
                                                    }
                                                  `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="_7._reception" data-name="7. reception">
                                                    <rect className="cls-11" x="1" y="25.62" width="40" height="3.85" />
                                                    <rect className="cls-11" x="3.86" y="29.46" width="34.29"
                                                        height="11.54" />
                                                    <ellipse className="cls-12" cx="11.36" cy="10.62" rx="2.5" ry="2.69" />
                                                    <path className="cls-11"
                                                        d="M3.86,24.08v1.54h15V24.46c0-4.46-3.36-8.08-7.5-8.08S3.86,20,3.86,24.46Z" />
                                                    <path className="cls-11"
                                                        d="M6.71,22.54H9.57A1.49,1.49,0,0,1,11,24.08h0a1.49,1.49,0,0,1-1.43,1.54H6.71" />
                                                    <path className="cls-11"
                                                        d="M16,22.54H13.14a1.49,1.49,0,0,0-1.43,1.54h0a1.49,1.49,0,0,0,1.43,1.54H16" />
                                                    <rect className="cls-11" x="28.14" y="17.92" width="10" height="7.69" />
                                                    <ellipse className="cls-11" cx="33.14" cy="4.85" rx="3.57" ry="3.85" />
                                                    <line className="cls-11" x1="33.14" y1="3.31" x2="33.14" y2="4.85" />
                                                    <line className="cls-11" x1="32.43" y1="4.85" x2="33.14" y2="4.85" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Reception Dossier Banque ({nbrReception})</span>
                                </div>

                                <div className="with-color">
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-b1,
                                                    .cls-b2 {
                                                    fill: none;
                                                    stroke: #fff;
                                                    stroke-linecap: round;
                                                    stroke-linejoin: round;
                                                    }
        
                                                    .cls-b1 {
                                                    stroke-width: 2px;
                                                    }
        
                                                    .cls-b2 {
                                                    stroke-width: 2px;
                                                    }
                                                  `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_1-2" data-name="Layer 1">
                                                <g id="_7._reception" data-name="7. reception">
                                                    <rect className="cls-b1" x="1" y="25.62" width="40" height="3.85" />
                                                    <rect className="cls-b1" x="3.86" y="29.46" width="34.29"
                                                        height="11.54" />
                                                    <ellipse className="cls-b2" cx="11.36" cy="10.62" rx="2.5" ry="2.69" />
                                                    <path className="cls-b1"
                                                        d="M3.86,24.08v1.54h15V24.46c0-4.46-3.36-8.08-7.5-8.08S3.86,20,3.86,24.46Z" />
                                                    <path className="cls-b1"
                                                        d="M6.71,22.54H9.57A1.49,1.49,0,0,1,11,24.08h0a1.49,1.49,0,0,1-1.43,1.54H6.71" />
                                                    <path className="cls-b1"
                                                        d="M16,22.54H13.14a1.49,1.49,0,0,0-1.43,1.54h0a1.49,1.49,0,0,0,1.43,1.54H16" />
                                                    <rect className="cls-b1" x="28.14" y="17.92" width="10" height="7.69" />
                                                    <ellipse className="cls-b1" cx="33.14" cy="4.85" rx="3.57" ry="3.85" />
                                                    <line className="cls-b1" x1="33.14" y1="3.31" x2="33.14" y2="4.85" />
                                                    <line className="cls-b1" x1="32.43" y1="4.85" x2="33.14" y2="4.85" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Reception Dossier Banque ({nbrReception})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('6', e)}>
                                <div className="no-color">
                                    <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-1 {
                                                        fill: #535353;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_2-2" data-name="Layer 2">
                                                <path className="cls-1"
                                                    d="M0,.89V34.11A1,1,0,0,0,1,35H34a1,1,0,0,0,1-.89V.89A1,1,0,0,0,34,0H1A1,1,0,0,0,0,.89ZM33,33.22H2V1.78H33Z" />
                                                <path className="cls-1"
                                                    d="M9.67,11.87h5.18V9.52H27.53a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9H14.85V5.88a1,1,0,0,0-1-.89H10.69a1,1,0,0,0-1,.89V7.73H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h2.2Zm2-2.39V6.74h1.1v3.31h-1.1Z" />
                                                <path className="cls-1"
                                                    d="M22.52,21.14h3.14a1,1,0,0,0,1-.9V18.43h.85a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-.85V15.15a1,1,0,0,0-1-.89H22.52a1,1,0,0,0-1,.89v1.49h-14a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h14v1.81A1,1,0,0,0,22.52,21.14Zm1-2.75V16h1.1v3.32h-1.1Z" />
                                                <path className="cls-1"
                                                    d="M14.77,30.05h3.14a1,1,0,0,0,1-.9V27.34h8.6a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-8.6V24.06a1,1,0,0,0-1-.89H14.77a1,1,0,0,0-1,.89v1.49H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h6.28v1.81A1,1,0,0,0,14.77,30.05Zm1-2.75V24.91h1.1v3.32h-1.1Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Contrôle Dossier Physique Banque ({nbrControl})</span>
                                </div>

                                <div className="with-color">
                                    <svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-b {
                                                        fill: #fff;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_2-2" data-name="Layer 2">
                                                <path className="cls-b"
                                                    d="M0,.89V34.11A1,1,0,0,0,1,35H34a1,1,0,0,0,1-.89V.89A1,1,0,0,0,34,0H1A1,1,0,0,0,0,.89ZM33,33.22H2V1.78H33Z" />
                                                <path className="cls-b"
                                                    d="M9.67,11.87h5.18V9.52H27.53a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9H14.85V5.88a1,1,0,0,0-1-.89H10.69a1,1,0,0,0-1,.89V7.73H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h2.2Zm2-2.39V6.74h1.1v3.31h-1.1Z" />
                                                <path className="cls-b"
                                                    d="M22.52,21.14h3.14a1,1,0,0,0,1-.9V18.43h.85a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-.85V15.15a1,1,0,0,0-1-.89H22.52a1,1,0,0,0-1,.89v1.49h-14a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h14v1.81A1,1,0,0,0,22.52,21.14Zm1-2.75V16h1.1v3.32h-1.1Z" />
                                                <path className="cls-b"
                                                    d="M14.77,30.05h3.14a1,1,0,0,0,1-.9V27.34h8.6a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-8.6V24.06a1,1,0,0,0-1-.89H14.77a1,1,0,0,0-1,.89v1.49H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h6.28v1.81A1,1,0,0,0,14.77,30.05Zm1-2.75V24.91h1.1v3.32h-1.1Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Contrôle Dossier Physique Banque ({nbrControl})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('7', e)}>
                                <div className="no-color">
                                    <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 35">
                                        <defs>
                                            <style>
                                                {`
                                                     .cls-1 {
                                                        fill: #535353;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_4" data-name="Layer 4">
                                                <path className="cls-1"
                                                    d="M20.37,35a3.63,3.63,0,0,0,.69-.08,6.61,6.61,0,0,0,1,.08,8,8,0,0,0,3.14-15.28V8.53a1,1,0,0,0-.29-.7L24,7l-.1-.12L17.4.29A1,1,0,0,0,16.7,0H9.76a1,1,0,0,0,0,2h6a1.49,1.49,0,0,0-.06.43V6.56A2.93,2.93,0,0,0,18.65,9.5h4.17a1.25,1.25,0,0,0,.43-.07v9.71h-.11A7.06,7.06,0,0,0,22.05,19a8,8,0,0,0-7.94,8,8.22,8.22,0,0,0,.17,1.58c0,.17.09.32.13.48a7.81,7.81,0,0,0,.35,1c.07.16.14.32.22.48a7.91,7.91,0,0,0,.56.93c.09.13.17.27.27.39a7.7,7.7,0,0,0,1,1.06l.05.06h-12a2.89,2.89,0,0,1-2.87-2.92V4.87A2.89,2.89,0,0,1,4.81,2H6.43A1,1,0,0,0,6.43,0H4.81A4.84,4.84,0,0,0,0,4.87V30.13A4.84,4.84,0,0,0,4.81,35ZM18.65,7.55a1,1,0,0,1-1-1V3.32l4.21,4.23ZM16.05,27a6,6,0,0,1,6-6.06,6.51,6.51,0,0,1,1.48.2l.41.11A6.11,6.11,0,0,1,28.06,27a6,6,0,0,1-6,6,6.43,6.43,0,0,1-1-.07l-.22,0A6,6,0,0,1,16.05,27Z" />
                                                <path className="cls-1"
                                                    d="M20.61,29.25a1,1,0,0,0,.68.29,1,1,0,0,0,.69-.29L25,26.17a1,1,0,1,0-1.37-1.37l-2.38,2.39-.85-.85a1,1,0,0,0-1.37,1.37Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Suivi Dossier Au Niveau Banque ({nbrFollow})</span>
                                </div>

                                <div className="with-color">
                                    <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 35">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-b {
                                                        fill: #fff;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Layer_4" data-name="Layer 4">
                                                <path className="cls-b"
                                                    d="M20.37,35a3.63,3.63,0,0,0,.69-.08,6.61,6.61,0,0,0,1,.08,8,8,0,0,0,3.14-15.28V8.53a1,1,0,0,0-.29-.7L24,7l-.1-.12L17.4.29A1,1,0,0,0,16.7,0H9.76a1,1,0,0,0,0,2h6a1.49,1.49,0,0,0-.06.43V6.56A2.93,2.93,0,0,0,18.65,9.5h4.17a1.25,1.25,0,0,0,.43-.07v9.71h-.11A7.06,7.06,0,0,0,22.05,19a8,8,0,0,0-7.94,8,8.22,8.22,0,0,0,.17,1.58c0,.17.09.32.13.48a7.81,7.81,0,0,0,.35,1c.07.16.14.32.22.48a7.91,7.91,0,0,0,.56.93c.09.13.17.27.27.39a7.7,7.7,0,0,0,1,1.06l.05.06h-12a2.89,2.89,0,0,1-2.87-2.92V4.87A2.89,2.89,0,0,1,4.81,2H6.43A1,1,0,0,0,6.43,0H4.81A4.84,4.84,0,0,0,0,4.87V30.13A4.84,4.84,0,0,0,4.81,35ZM18.65,7.55a1,1,0,0,1-1-1V3.32l4.21,4.23ZM16.05,27a6,6,0,0,1,6-6.06,6.51,6.51,0,0,1,1.48.2l.41.11A6.11,6.11,0,0,1,28.06,27a6,6,0,0,1-6,6,6.43,6.43,0,0,1-1-.07l-.22,0A6,6,0,0,1,16.05,27Z" />
                                                <path className="cls-b"
                                                    d="M20.61,29.25a1,1,0,0,0,.68.29,1,1,0,0,0,.69-.29L25,26.17a1,1,0,1,0-1.37-1.37l-2.38,2.39-.85-.85a1,1,0,0,0-1.37,1.37Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Suivi Dossier Au Niveau Banque ({nbrFollow})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('8', e)}>
                                <div className="no-color">
                                    <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-1 {
                                                        fill: #535353;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Слой_1" data-name="Слой 1">
                                                <path className="cls-1"
                                                    d="M.55,35A.55.55,0,0,1,0,34.44a.59.59,0,0,1,0-.14l2.24-8.21A17.48,17.48,0,1,1,9,32.79L.69,35Zm8.52-3.34a.5.5,0,0,1,.27.07,16.41,16.41,0,1,0-6-6,.56.56,0,0,1,0,.42l-2,7.52,7.61-2Z" />
                                                <path className="cls-1"
                                                    d="M17.5,12.57A3.56,3.56,0,1,1,21.05,9,3.56,3.56,0,0,1,17.5,12.57Zm0-6A2.47,2.47,0,1,0,20,9,2.46,2.46,0,0,0,17.5,6.55Z" />
                                                <path className="cls-1"
                                                    d="M17.5,29.52A3.55,3.55,0,0,1,13.94,26V18.58a3.56,3.56,0,0,1,7.11,0V26A3.55,3.55,0,0,1,17.5,29.52Zm0-13.4A2.46,2.46,0,0,0,15,18.58V26A2.46,2.46,0,0,0,20,26V18.58A2.46,2.46,0,0,0,17.5,16.12Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Complément d'Information Banque ({nbrComplement})</span>
                                </div>

                                <div className="with-color">
                                    <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-b {
                                                        fill: #fff;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Слой_1" data-name="Слой 1">
                                                <path className="cls-b"
                                                    d="M.55,35A.55.55,0,0,1,0,34.44a.59.59,0,0,1,0-.14l2.24-8.21A17.48,17.48,0,1,1,9,32.79L.69,35Zm8.52-3.34a.5.5,0,0,1,.27.07,16.41,16.41,0,1,0-6-6,.56.56,0,0,1,0,.42l-2,7.52,7.61-2Z" />
                                                <path className="cls-b"
                                                    d="M17.5,12.57A3.56,3.56,0,1,1,21.05,9,3.56,3.56,0,0,1,17.5,12.57Zm0-6A2.47,2.47,0,1,0,20,9,2.46,2.46,0,0,0,17.5,6.55Z" />
                                                <path className="cls-b"
                                                    d="M17.5,29.52A3.55,3.55,0,0,1,13.94,26V18.58a3.56,3.56,0,0,1,7.11,0V26A3.55,3.55,0,0,1,17.5,29.52Zm0-13.4A2.46,2.46,0,0,0,15,18.58V26A2.46,2.46,0,0,0,20,26V18.58A2.46,2.46,0,0,0,17.5,16.12Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Complément d'Information Banque ({nbrComplement})</span>
                                </div>
                            </li>
                            <li onClick={e => tache_active('9', e)}>
                                <div className="no-color">
                                    <svg width="25" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 34">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-1 {
                                                        fill: #535353;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Capa_1" data-name="Capa 1">
                                                <rect className="cls-1" x="31.25" y="24.08" width="1.25" height="4.25" />
                                                <rect className="cls-1" x="31.25" y="29.75" width="1.25" height="1.42" />
                                                <path className="cls-1"
                                                    d="M40,34l-3.75-7.22V8.5a2.69,2.69,0,0,0-2.5-2.83h-15L16.25,0H2.5A2.68,2.68,0,0,0,0,2.83v25.5a2.69,2.69,0,0,0,2.5,2.84H25.25L23.75,34Zm-2.25-1.42H25.88l6-11.33ZM1.25,2.83A1.35,1.35,0,0,1,2.5,1.42h13L18,7.08H33.75A1.35,1.35,0,0,1,35,8.5v2.83H1.25ZM2.5,29.75a1.35,1.35,0,0,1-1.25-1.42V12.75H35V24.37l-3.12-5.95L26,29.75Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Rejeté ({nbrReject})</span>
                                </div>

                                <div className="with-color">
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 34">
                                        <defs>
                                            <style>
                                                {`
                                                    .cls-b {
                                                        fill: #fff;
                                                        }
                                                   `}
                                            </style>
                                        </defs>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="Capa_1" data-name="Capa 1">
                                                <rect className="cls-b" x="31.25" y="24.08" width="1.25" height="4.25" />
                                                <rect className="cls-b" x="31.25" y="29.75" width="1.25" height="1.42" />
                                                <path className="cls-b"
                                                    d="M40,34l-3.75-7.22V8.5a2.69,2.69,0,0,0-2.5-2.83h-15L16.25,0H2.5A2.68,2.68,0,0,0,0,2.83v25.5a2.69,2.69,0,0,0,2.5,2.84H25.25L23.75,34Zm-2.25-1.42H25.88l6-11.33ZM1.25,2.83A1.35,1.35,0,0,1,2.5,1.42h13L18,7.08H33.75A1.35,1.35,0,0,1,35,8.5v2.83H1.25ZM2.5,29.75a1.35,1.35,0,0,1-1.25-1.42V12.75H35V24.37l-3.12-5.95L26,29.75Z" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Rejeté ({nbrReject})</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-12 padding-left margin-bottom">
                    <div className="commands-section">
                        <h2>Tableau des Commandes</h2>

                        {/* Begin Table  */}
                        {
                            tableShow ? 
                              (demandes?.length !== 0) ?
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nom</th>
                                            <th>Prenom</th>
                                            <th>MT Produit</th>
                                            <th>Dossier Client</th>
                                            <th>Date Dossier Client</th>
                                            <th>Dossier Garantie</th>
                                            <th>Deuxiéme Dossier</th>
                                            <th>Dossier Banque</th>
                                            <th>Date Dossier Banque</th>
                                            <th>Etat</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            demandes?.map(demande => (
                                                <tr key={demande.id}>
                                                    <td>{demande.numero_demande}</td>
                                                    <td>{checkSize(demande.nom)}</td>
                                                    <td>{checkSize(demande.prenom)}</td>
                                                    <td>{demande.mt_produit}</td>
                                                    {
                                                        demande.dossierClient ? (
                                                            <td>
                                                                <i style={{ fontSize: '24px', color: '#159157' }} className="fa fa-check" aria-hidden="true"></i>
                                                            </td>
                                                        ) : (
                                                            <td>{'-----'}</td>
                                                        )
                                                    }
                                                    {
                                                        demande.date_dossier_client ? <td>{getDate(demande.date_dossier_client)}</td>
                                                            : <td>{'-----'}</td>
                                                    }
                                                    {
                                                        demande.dossier_garantie ? <td><i style={{ fontSize: '24px', color: '#159157' }} className="fa fa-check" aria-hidden="true"></i></td>
                                                            : <td>{'-----'}</td>
                                                    }
                                                    {
                                                        demande.deuxieme_dossier ? <td><i style={{ fontSize: '24px', color: '#159157' }} className="fa fa-check" aria-hidden="true"></i></td>
                                                            : <td>{'-----'}</td>
                                                    }
                                                    {
                                                        demande.dossier_banque ? <td><i style={{ fontSize: '24px', color: '#159157' }} className="fa fa-check" aria-hidden="true"></i></td>
                                                            : <td style={{ cursor: 'pointer' }} onClick={() => setHash(demande.hash)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <div id="rect-div"></div>
                                                            </td>
                                                    }
                                                    {
                                                        demande.dossier_banque_date ? <td>{getDate(demande.dossier_banque_date)}</td> : <td>{'-----'}</td>
                                                    }
                                                    {
                                                        <td className='etat'>
                                                           <span className={
                                                                demande.etat === 0
                                                                    ? 'non-traite-color'
                                                                    : demande.etat === 1
                                                                        ? 'refuse-color'
                                                                        : demande.etat === 2
                                                                            ? 'accepte-color'
                                                                            : demande.etat === 3
                                                                                ? 'approuve-color'
                                                                                : demande.etat === 4
                                                                                    ? 'annule_par_client'
                                                                                    : demande.etat === 5
                                                                                        ? 'accuse'
                                                                                        : demande.etat === 6
                                                                                            ? 'financable-color'
                                                                                            : demande.etat === 7
                                                                                                ? 'non-financable-color'
                                                                                                : demande.etat === 8
                                                                                                    ? 'controler-color'
                                                                                                : ''}>
                                                                {getStatus(demande.etat)}
                                                            </span>
                                                        </td>
                                                    }
                                                    <td onClick={() => navigate(`/detail/${demande.hash}`)}>
                                                        <svg width="37" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.78 45.36">
                                                            <defs>
                                                                <style>
                                                                    {`
                                                                          .cls-c1 {
                                                                            fill: #e0f6f6;
                                                                            }
                            
                                                                            .cls-c2,
                                                                            .cls-c3 {
                                                                            fill: none;
                                                                            stroke: #1db9aa;
                                                                            stroke-linecap: round;
                                                                            stroke-linejoin: round;
                                                                            }
                            
                                                                            .cls-c2 {
                                                                            stroke-width: 2px;
                                                                            }
                            
                                                                            .cls-c3 {
                                                                            stroke-width: 2px;
                                                                            }
                                                                        `}
                                                                </style>
                                                            </defs>
                                                            <g id="Layer_2" data-name="Layer 2">
                                                                <g id="Layer_1-2" data-name="Layer 1">
                                                                    <polygon className="cls-c1"
                                                                        points="50.78 10.16 50.78 35.2 40.2 45.35 10.38 45.35 0 35.39 0 9.97 10.38 0 40.2 0 50.78 10.16" />
                                                                    <path className="cls-c2"
                                                                        d="M28,27l3.58-3.59h0l9,9a2.54,2.54,0,0,1,0,3.59h0a2.54,2.54,0,0,1-3.59,0h0l-9-9h0Z" />
                                                                    <circle className="cls-c3" cx="21.69" cy="17.12" r="8.45" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                               : <div className='no-data-wrapper'>Pas de Données Disponibles</div>
                             : (
                                <LoadingComponent />
                            )
                        }
                        {/* End Table */}

                        {/* Begin Pagination */}
                        {
                            (tableShow && pages && pages>1) ? (
                                <div className="pagination">
                                    {
                                        parseInt(pages) <= 8 ? (
                                            <div className="pagination-content">
                                                <span onClick={onclickPrevious}>
                                                    <i className="fa-solid fa-angle-left"></i>
                                                </span>
                                                <span onClick={() => navigate(`/page/${1}`)} > Premier </span>
                                                {
                                                    [...Array(pages).keys()].map(x => (
                                                        <span key={x + 1} className={x + 1 == page ? 'page-number-active' : ''} onClick={() => navigate(`/page/${x + 1}`)}>
                                                            {x + 1}
                                                        </span>
                                                    ))
                                                }
                                                <span onClick={() => navigate(`/page/${pages}`)} > Dernier </span>
                                                <span onClick={onclickNext} >
                                                    <i className="fa-solid fa-angle-right"></i>
                                                </span>
                                            </div>
                                        ) :
                                            (
                                                <div className="pagination-content">
                                                    <span onClick={onclickPrevious}>
                                                        <i className="fa-solid fa-angle-left"></i>
                                                    </span>
                                                    <span onClick={() => navigate(`/page/${1}`)} > Premier </span>
                                                    {
                                                        (pageNumber - 1 > 0) && (
                                                            <span onClick={() => navigate(`/page/${pageNumber - 1}`)} > {pageNumber - 1} </span>
                                                        )
                                                    }
                                                    <span className={pageNumber == page ? 'page-number-active' : ''} onClick={() => navigate(`/page/${pageNumber}`)}>
                                                        {pageNumber}
                                                    </span>
                                                    {
                                                        (pageNumber + 1 <= pages) && (
                                                            <span className={pageNumber + 1 == page ? 'page-number-active' : ''} onClick={() => navigate(`/page/${pageNumber + 1}`)}>
                                                                {pageNumber + 1}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (pageNumber === 1) && (
                                                            <span onClick={() => navigate(`/page/${pageNumber + 2}`)}>{pageNumber + 2}</span>
                                                        )
                                                    }
                                                    {
                                                        (pageNumber + 1 !== pages - 2) && (
                                                            <span>{'....'}</span>
                                                        )
                                                    }
                                                    <span onClick={() => navigate(`/page/${pages - 1}`)} className={pages - 1 == page ? 'page-number-active' : ''}>
                                                        {pages - 1}
                                                    </span>
                                                    <span onClick={() => navigate(`/page/${pages}`)} className={pages == page ? 'page-number-active' : ''}>
                                                        {pages}
                                                    </span>
                                                    <span onClick={() => navigate(`/page/${pages}`)} > Dernier </span>
                                                    <span onClick={onclickNext} >
                                                        <i className="fa-solid fa-angle-right"></i>
                                                    </span>
                                                </div>
                                            )
                                    }
                                </div>
                            ) : ""
                        }
                        {/* End Pagination */}



                    </div>
                </div>
            </div>

            {/* Begin Modal  */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Message de Confirmation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Êtes-vous sûr de vouloir Activer Dossier Banque
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => onCheckDossierBanque()}>Oui</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Non</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Modal */}

        </div>
    );
}



export default ListCommands