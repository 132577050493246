import React from 'react'
import { useEffect } from 'react'
import '../css/DetailHostorical.css'
import SimpleDateTime from 'react-simple-timestamp-to-date';

const DetailHistorical = ({ historicalChild, DisplayModalHistoricalChild, setDisplayModalHistoricalChild }) => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        console.log(historicalChild);
    }, [DisplayModalHistoricalChild]);

    const getStatus = (number) => {
        if (number === '0') {
            return "Non_Traité";
        }
        else if (number === '1') {
            return "Accepter";
        }
        else if (number === '2') {
            return "Refuser";
        }
        else if (number === '3') {
            return "Approuver";
        }
        else if (number === '4') {
            return "Annuler";
        }
        else if (number === '5') {
            return 'Financable'
        }
        else if (number === '6') {
            return 'Non Financable'
        }
        else if (number === '7') {
            return 'Rejeter'
        }
        else if (number === '8') {
            return 'Controler'
        }
        else {
            return ''
        }
    }

    let getTime = (dateString) => {
        let dd = parseInt(dateString);
        var s = new Date(dd * 1000).toLocaleString("fr-FR")
        return s;
    }

    return (
        <div className={DisplayModalHistoricalChild ? 'historical-modal display-historical-modal' : 'historical-modal'}>
            <div className='historical-back' onClick={() => setDisplayModalHistoricalChild(false)}></div>
            {
                historicalChild.length !== 0 ? (<div className='historical-line'></div>) : ""
            }
            {
                historicalChild.length !== 0 ? (
                    historicalChild.map((hist, index) => (
                        <div key={index} className='row'>
                            <div className={(index % 2) === 0 ? 'col-lg-6' : 'col-lg-6 offset-lg-6'}>
                                <div className='box-historical'>
                                    <div className='title-historical'>
                                        <span className={
                                            hist.etat === '0'
                                                ? 'non-traite-color'
                                                : hist.etat === '1'
                                                    ? 'refuse-color'
                                                    : hist.etat === '2'
                                                        ? 'accepte-color'
                                                        : hist.etat === '3'
                                                            ? 'approuve-color'
                                                            : hist.etat === '4'
                                                                ? 'annule_par_client'
                                                                : hist.etat === '5'
                                                                    ? 'accuse'
                                                                    : hist.etat === '6'
                                                                        ? 'financable-color'
                                                                        : hist.etat === '7'
                                                                            ? 'non-financable-color'
                                                                            : hist.etat === '8'
                                                                                ? 'controler-color'
                                                                                : ''}>
                                            {getStatus(hist.etat)}
                                        </span>
                                    </div>
                                    <div className='content-historical'>
                                        <div className='line-historical'>
                                            {/* <span>Date : {hist.dateIns.timestamp}</span> */}
                                            <span>Date  :  {getTime(hist.dateIns.timestamp)}</span>
                                        </div>
                                        <div className='line-historical'><span>Commentaire  :   {hist.motifRefus}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='no-historical'>
                        <span>Pas d'Historique</span>
                    </div>
                )
            }

        </div>
    )
}

export default DetailHistorical